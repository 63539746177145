<template>
  <div></div>
</template>

<script>
import pdfMake from "pdfmake";
import htmlToPdfmake from "html-to-pdfmake";
import pdfFonts from "../../../assets/build/vfs_fonts";
import apiConfig from "../../../config/api";

import moment from "moment";

import { Decode, Encode } from "@/services";
import userApi from "@/api/user/";
import { toFixed } from "ant-design-vue/lib/input-number/src/utils/MiniDecimal";

import { ArabicNumberToText } from "../../../utils/numberToText";

import JsBarcode from "jsbarcode/bin/JsBarcode";

export default {
  props: {
    dataExport: Object,
  },
  data: () => ({
    newProduct: [],
    user: "",
    numberToTh: "",
    sumRequestStockAmt:null
  }),
  async created() {
    console.log(this.dataExport);
    // await this.getUser();
    await this.exports();
  },

  methods: {
    // setProduct() {
    //   this.dataExport.cutOffStockItems.forEach((element, index) => {
    //     if (index === 0) {
    //       this.newProduct.push([
    //         {
    //           rowSpan: 2,
    //           fontSize: 9,
    //           text: "รหัส\nID",
    //         },

    //         {
    //           rowSpan: 2,
    //           fontSize: 9,
    //           text: "คำอธิบาย\nDescription",
    //         },
    //         {
    //           rowSpan: 2,
    //           fontSize: 9,
    //           text: "จำนวน\nQuantity",
    //           alignment: "right",
    //         },
    //         {
    //           rowSpan: 2,
    //           fontSize: 9,
    //           text: "ราคา/หน่วย\nPrice/Unit",
    //           alignment: "right",
    //         },
    //         {
    //           rowSpan: 2,
    //           fontSize: 9,
    //           text: "ส่วนลด/หน่วย\nDiscount/Unit",
    //           alignment: "right",
    //         },
    //         {
    //           rowSpan: 2,
    //           fontSize: 9,
    //           text: "ภาษี (%)\nVAT (%)",
    //           alignment: "right",
    //         },
    //         {
    //           rowSpan: 2,
    //           fontSize: 9,
    //           text: "มูลค่า\nAmount",
    //           alignment: "right",
    //         },
    //       ]);
    //       this.newProduct.push(["rowspan"]);
    //     }
    //     this.newProduct.push([
    //       {
    //         text: element.product.product_code,
    //         fontSize: 9,
    //         alignment: "right",
    //       },
    //       {
    //         text: element.product.product_desc,
    //         fontSize: 9,
    //       },
    //       {
    //         text: element.item_amt,
    //         fontSize: 9,
    //         alignment: "right",
    //       },
    //       {
    //         text: element.product.product_cost_price,
    //         fontSize: 9,
    //         alignment: "right",
    //       },
    //       {
    //         text: element.item_discount
    //           ? element.item_discount
    //           : (0).toFixed(2),
    //         fontSize: 9,
    //         alignment: "right",
    //       },
    //       {
    //         text: "-",
    //         fontSize: 9,
    //         alignment: "right",
    //       },
    //       {
    //         text: element.item_amt * element.product.product_cost_price,
    //         fontSize: 9,
    //         alignment: "right",
    //       },
    //     ]);
    //   });
    // },

    async getUser() {
      const userId = Decode.decode(localStorage.getItem("boss"));

      let responseData = [];

      try {
        responseData = await userApi.user.getAll();
      } catch (error) {
        console.log(error);
      }
      if (responseData.response_status === "SUCCESS") {
        const users = responseData.data;
        const findUser = users.find((data) => data.id === parseInt(userId));
        if (findUser) {
          this.user = findUser;
        }
      }
    },

    textToBase64Barcode(text) {
      let canvas = document.createElement("canvas");
      JsBarcode(canvas, text, { format: "CODE39", displayValue: false });

      return canvas.toDataURL("image/png");
    },

    exports() {
      const newWindow = window.open("", "_blank");

      pdfMake.vfs = pdfFonts.pdfMake.vfs;
      pdfMake.fonts = {
        Roboto: {
          normal: "Kanit-Regular.ttf",
          bold: "Kanit-Medium.ttf",
          italics: "Kanit-Medium.ttf",
          bolditalics: "Kanit-Medium.ttf",
        },
      };

      //setProduct Arr
      this.setProduct();

      // convert num to text TH
      // this.convertNumberToTh();

      const html = [
        {
          text: `ใบรับสินค้าระหว่างคลัง`,
          fontSize: 14,
        },
        {
          text: "WAREHOUSE TO WAREHOUSE",
          margin: [0, 0, 0, 10],
          fontSize: 14,
        },

        // {
        //   columns: [
        //     {
        //       width: "50%",
        //       text: `จากคลัง ${this.dataExport.form_warehouse_id}`,
        //       fontSize: 10,
        //     },
        //     {
        //       width: "50%",
        //       text: `ถึงคลัง ${this.dataExport.to_warehouse_id}`,
        //       alignment: "right",
        //       fontSize: 10,
        //     },
        //   ],
        //   columnGap: 10,
        //   margin: [0, 0, 0, 5],
        // },

        {
          columns: [
            {
              width: "70%",
              // text: `ที่อยู่/Address. ${this.dataExport.transferee_address}`,
              text: ``,
              fontSize: 10,
            },
            {
              width: "30%",
              text: `วันที่ ${this.dataExport.createdAt}`,
              alignment: "right",
              fontSize: 10,
            },
          ],
          columnGap: 10,
          margin: [0, 0, 0, 2],
        },

        {
          table: {
            widths: ["75%", "25%"],
            body: [
              [
                {
                  text: `จากคลัง ${this.dataExport.fromWareHouse.warehouse_short_name} ${this.dataExport.fromWareHouse.warehouse_full_name}`,
                  fontSize: 10,
                  border: [false, false, false, false],
                  margin: [-5, 0, 0, 0],
                },

                {
                  rowSpan: 2,
                  image: this.textToBase64Barcode(
                    `${this.dataExport.transaction_number}`
                  ),
                  // qr: `${apiConfig.ENDPOINT_CLIENT}#/purchase/PU2-1/edit?id=${this.dataExport.id}`,
                  width: 130,
                  height: 35,
                  border: [false, false, false, false],
                  margin: [0, 0, -5, 0],
                },
              ],
              [
                {
                  text: `ถึงคลัง ${this.dataExport.toWareHouse.warehouse_short_name} ${this.dataExport.toWareHouse.warehouse_full_name}`,
                  fontSize: 10,
                  border: [false, false, false, false],
                  margin: [-5, 0, 0, 0],
                },
                "star-sized",
              ],
            ],
          },
        },

        // LINE SOLID
        {
          canvas: [
            {
              type: "line",
              x1: 0,
              y1: 5,
              x2: 595 - 2 * 40,
              y2: 5,
              lineWidth: 1,
            },
          ],
          margin: [0, 0, 0, 5],
        },

        // TABLE
        {
          style: "tableExample",
          table: {
            widths: [20, 100, "*", 50, 50, 50],
            body: this.newProduct,
          },
        },

        // REMARK

        {
          columns: [
            {
              width: 396.5,
              columns: [{ text: "" }],
            },
            {
              table: {
                widths: ["*", "*"],
                heights: 20,

                body: [
                  [
                    { text: this.sumRequestStockAmt, border: [false, false, false, false] },
                    { text: "row 1", border: [false, false, false, false] },
                  ],
                  [
                    { text: "row 2", border: [false, false, false, false] },
                    { text: "row 2", border: [false, false, false, false] },
                  ],
                ],
              },
            },
          ],
        },

        // THAI TEXT
        // {
        //   columns: [
        //     {
        //       table: {
        //         widths: ["50%", "50%"],
        //         body: [
        //           [
        //             {
        //               text: "จำนวนเงินรวมทั้งสิ้น",
        //               fontSize: 14,
        //               alignment: "right",
        //               border: [false, false, false, false],
        //               bold: true,
        //             },
        //             {
        //               text: this.numberToTh,
        //               fontSize: 14,
        //               alignment: "right",
        //               border: [false, false, false, false],
        //               margin: [0, 0, -5, 0],
        //               bold: true,
        //             },
        //           ],
        //         ],
        //       },
        //     },
        //   ],
        //   columnGap: 10,
        //   margin: [0, 10, 0, 5],
        // },

        // APPROVE
        {
          columns: [
            {
              text: "Checker",
              fontSize: 10,
              margin: [0, 10, 0, 0],
            },
            {
              text: "",
            },
            {
              text: "Receiver",
              fontSize: 10,
              margin: [0, 10, 0, 0],
            },
          ],
          columnGap: 2,
          margin: [0, 0, 0, 10],
        },

        // SIGNATURE
        {
          columns: [
            {
              width: "50%",
              text: "..............................................................................................",
              fontSize: 10,
              margin: [0, 10, 0, 0],
            },
            {
              width: "50%",
              text: "..............................................................................................",
              fontSize: 10,
              margin: [0, 10, 0, 0],
              alignment: "right",
            },
          ],
          columnGap: 10,
          margin: [0, 0, 0, 5],
        },
        {
          columns: [
            {
              width: "50%",
              text: "(..............................................................................................)",
              fontSize: 10,
              margin: [0, 10, 0, 0],
            },
            {
              width: "50%",
              text: "(..............................................................................................)",
              fontSize: 10,
              margin: [0, 10, 0, 0],
              alignment: "right",
            },
          ],
          columnGap: 10,
          margin: [0, 0, 0, 5],
        },
        {
          columns: [
            {
              width: "50%",
              text: "วันที่/Date........................................................................",
              fontSize: 10,
              margin: [0, 10, 0, 0],
            },
            {
              width: "50%",
              text: "วันที่/Date........................................................................",
              fontSize: 10,
              margin: [0, 10, 0, 0],
              alignment: "right",
            },
          ],
          columnGap: 10,
          margin: [0, 0, 0, 5],
        },
      ];

      const pdfData = {
        content: html,
      };
      pdfMake.createPdf(pdfData).open({}, newWindow);
      this.$emit("generatedFile");
    },
    textToBase64Barcode(text) {
      let canvas = document.createElement("canvas");
      JsBarcode(canvas, text, { format: "CODE128", displayValue: false });

      return canvas.toDataURL("image/png");
    },
    setTotal() {
      // sumRequestStockAmt;
      this.dataExport.productTransfers.forEach((element) => {
        this.sumRequestStockAmt += element.requestStockAmt
      });
    },
    setProduct() {
      this.dataExport.productTransfers.forEach((element, index) => {
        if (index === 0) {
          this.newProduct.push([
            {
              rowSpan: 2,
              fontSize: 9,
              text: "",
            },
            {
              rowSpan: 2,
              fontSize: 9,
              text: "Item No",
            },
            {
              rowSpan: 2,
              fontSize: 9,
              text: "Name",
            },
            {
              rowSpan: 2,
              fontSize: 9,
              text: "UNIT",
              alignment: "right",
            },
            {
              rowSpan: 2,
              fontSize: 9,
              text: "Qty",
              alignment: "right",
            },
            {
              rowSpan: 2,
              fontSize: 9,
              text: "Qty CTN",
              alignment: "right",
            },
          ]);
          this.newProduct.push(["rowspan"]);
        }
        this.newProduct.push([
          {
            text: index + 1,
            fontSize: 9,
            alignment: "right",
          },
          {
            text: element.code,
            fontSize: 9,
            alignment: "right",
          },
          {
            text: element.name,
            fontSize: 9,
          },
          {
            text: element.productUnit.name,
            fontSize: 9,
            alignment: "right",
          },
          {
            text: element.stockAmt ? element.stockAmt : "-",
            fontSize: 9,
            alignment: "right",
          },
          {
            text: element.requestStockAmt,
            fontSize: 9,
            alignment: "right",
          },
        ]);
      });
    },

    convertNumberToTh() {
      this.numberToTh = ArabicNumberToText(this.dataExport.final_price);
    },
  },
};
</script>
